.container {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	margin: 0 10px 0 10px;
}

.label {
	margin: 5px 0 0 10px;
	font-size: 16px;
}

.blue {
	border: solid 1px blue;
}

.deleteIconButton {
	border-radius: 50%;
	padding: 2px 5px;
	border: solid 1px black;
	cursor: pointer;
	&:hover {
		background-color: blue;
		color: white;
	}
}