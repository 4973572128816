@import "../../styles/index";

.default {
	width: fit-content;
	font-family: Roboto !important;
	font-weight: 400 !important;
	border-radius: 5px !important;
	height: fit-content;
}

.cancel {
	background-color: $grey-3 !important;
	color: $white !important;
	transition: background-color 1s linear;

	&:hover,
	&:focus,
	&:active,
	&.active {
		background-color: $grey-4 !important;
		transition: background-color 1s linear;
	}
}

.cancelLight {
	background-color: $grey-2 !important;
	color: $grey-3 !important;
	transition: background-color 1s linear;

	&:hover,
	&:focus,
	&:active,
	&.active {
		color: white !important;
	}

	&:hover,
	&:focus,
	&:active,
	&.active {
		background-color: $grey-3 !important;
		transition: background-color 1s linear;
	}
}

.primary {
	background-color: $primary-light !important;
	color: $white !important;
	transition: background-color 1s linear;

	&:hover,
	&:focus,
	&:active,
	&.active {
		background-color: $primary !important;
		transition: background-color 1s linear;
	}
}

.success {
	background-color: $success-lighter !important;
	color: $white !important;

	&:hover,
	&:focus,
	&:active,
	&.active {
		background-color: $success !important;
		transition: background-color 1s linear;
	}
}

.danger {
	background-color: $danger !important;
	color: $white !important;

	&:hover,
	&:focus,
	&:active,
	&.active {
		background-color: $danger-dark !important;
		transition: background-color 1s linear;
	}
}

.warning {
	background-color: $warning-light !important;
	color: $text-dark !important;

	&:hover,
	&:focus,
	&:active,
	&.active {
		background-color: $warning !important;
		transition: background-color 1s linear;
	}
}

.secondary {
	background-color: $secondary-dark !important;
	color: $white !important;
	transition: background-color 1s linear;

	&:hover,
	&:focus,
	&:active,
	&.active {
		background-color: $purple-secondary !important;
		transition: background-color 1s linear;
	}
}

.black {
	background-color: $grey-4 !important;
	color: $white !important;
	transition: background-color 1s linear;

	&:hover,
	&:focus,
	&:active,
	&.active {
		background-color: $grey-5 !important;
		transition: background-color 1s linear;
	}
}
.admin {
	background-color: $danger-lighter !important;
	color: $white !important;
	transition: background-color 1s linear;

	&:hover,
	&:focus,
	&:active,
	&.active {
		background-color: $danger-light !important;
		transition: background-color 1s linear;
	}
}
