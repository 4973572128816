@import "./styles/index.scss";

.headerSynthese {
    margin-bottom: 15px;
}

.header {
    margin-top: 5px;
    display: flex;
    justify-content: space-between;
	.buttonsContainer{
		display: flex;
		flex-wrap: wrap;
		gap: 5px;
	}
}

.principalContainer {
    overflow-y: auto;
    margin: 30px;
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    form {
        max-width: 600px !important;
        margin: auto;
    }
    &.synthese {
        table,
        th,
        td {
            border: 1px solid black;
            border-collapse: collapse;
        }

        table thead th {
            background-color: #d1e2eb;
        }

        th,
        td {
            text-align: left;
            padding: 5px 10px;
        }

        tbody tr td {
            cursor: pointer;
            text-align: center;
        }
    }
}

.rightSide {
    flex: 1;
}

.leftSide {
    flex: 1;
}
